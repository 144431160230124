<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('teaGardenBtriService.schedule') }} {{ $t('globalTrans.search')}}</h4>
      </template>
      <template v-slot:searchBody>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
            <b-form @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
            <b-row>
              <b-col xs="12" sm="12" md="3">
                <ValidationProvider name="fiscal_year_id" vid="fiscal_year_id" rules="" v-slot="{ errors }">
                  <b-form-group
                label-for="fiscal_year"
                >
                  <template v-slot:label>
                    {{ $t('globalTrans.fiscal_year') }}
                  </template>
                  <b-form-select
                    plain
                    id="fiscal_year"
                    :options="fiscalYearList"
                    v-model="search.fiscal_year_id"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="3">
                <ValidationProvider name="Event Type" vid="event_type" v-slot="{ errors }">
                    <b-form-group
                        label-for="event_type"
                    >
                    <template v-slot:label>
                        {{ $t('teaGardenBtriService.event_type') }}
                        </template>
                        <b-form-select
                            plain
                            id="fiscal_year"
                            :options="eventTypeList"
                            v-model="search.event_type"
                            :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                        <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="3">
                <ValidationProvider name="Date From" vid="date_from" v-slot="{ errors }">
                  <b-form-group
                    label-for="date_from"
                  >
                    <template v-slot:label>
                      {{ $t('globalTrans.date_from') }}
                    </template>
                    <date-picker
                      id="date_from"
                      class="form-control"
                      v-model="search.date_from"
                      :placeholder="$t('globalTrans.select')"
                    >
                    </date-picker>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="3">
                <ValidationProvider name="Date To" vid="date_to" v-slot="{ errors }">
                  <b-form-group
                    label-for="date_to"
                  >
                    <template v-slot:label>
                      {{ $t('globalTrans.date_to') }}
                    </template>
                    <date-picker
                      id="date_to"
                      class="form-control"
                      v-model="search.date_to"
                      :placeholder="$t('globalTrans.select')"
                    >
                    </date-picker>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            <b-col sm="9">
            </b-col>
            <b-col sm="3" style="text-align: right;">
              <b-button size="sm" type="submit" variant="primary" class="mt-20">
                <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
              </b-button>
            </b-col>
          </b-row>
          </b-form>
        </ValidationObserver>
        </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGardenBtriService.schedule') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <router-link class="btn-add" to="add-prepare-schedule"><i class="ri-add-fill"></i> {{ $t('globalTrans.add_new') }}</router-link>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <select-column-check v-on:editMethodInParent="editMethodInParent" :labelData="labelData" :search="search" :columns="columns" />
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(fiscal_year_id)="data">
                    {{ getFiscalYear(data.item.fiscal_year_id) }}
                  </template>
                  <template v-slot:cell(event_type)="data">
                    {{ getEventType(data.item.event_type) }}
                  </template>
                  <template v-slot:cell(event_name)="data">
                    {{ getEventName(data.item) }}
                  </template>
                  <template v-slot:cell(status)="data">
                    {{ getStatusType(data.item.status) }}
                  </template>
                  <template v-slot:cell(action)="data">
                    <router-link v-if="data.item.status === 1" class="action-btn edit" variant=" iq-bg-warning mr-1" :title="$t('globalTrans.edit')" :to="{name: 'tea_garden_service.btri.edit_prepare_schedule', params: {id: data.item.id}}"><i class="ri-pencil-fill"></i></router-link>
                    <b-button class="moc-action-btn moc-publish-btn" :title="$t('globalTrans.circulate')" @click="circulateSchedule(data.item.id)" v-if="data.item.status === 1">
                        <i class="ri-shield-check-line"></i>
                    </b-button>
                  <b-button class="moc-action-btn moc-view-btn" :title="$t('globalTrans.view')" @click="details(data.item)" v-b-modal.details>
                      <i class="ri-eye-line"></i>
                    </b-button>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
               </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <b-modal id="details" size="lg" :title="viewTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
     <Details :items="items" :key="viewitemId"/>
    </b-modal>
  </div>
</template>
<script>
// import Form from './Form.vue'
import Details from './Details.vue'
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { prepareScheduleList, prepareScheduleToggle } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/route-based-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    // Form,
    Details
  },
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
        sortBy: '',
        sortDesc: true,
        sortDirection: 'desc',
      search: {
        fiscal_year_id: 0,
        limit: this.$route.query.limit ? this.$route.query.limit : 10,
        date_from: '',
        date_to: '',
        time_to: '',
        time_from: '',
        event_type: this.$route.query.event_type ? this.$route.query.event_type : 0
      },
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '8%' } },
        { labels: 'teaGardenBtriService.schedule_id', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '12%' } },
        { labels: 'globalTrans.fiscal_year', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '12%' } },
        { labels: 'teaGardenBtriService.event_type', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '15%' } },
        { labels: 'teaGardenBtriService.event_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '15%' } },
        { labels: 'globalTrans.status', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '12%' } },
        { labels: 'globalTrans.action', class: 'text-left', show: '1', order: 7, thStyle: { width: '12%' } }
      ],
      items: [],
      viewitemId: 0,
      actions: {
        edit: true,
        toogle: true,
        delete: false
      },
      forwardedItemId: ''
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  computed: {
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'schedule_id' },
          { key: 'fiscal_year_id' },
          { key: 'event_type' },
          { key: 'event_name' },
          // { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'schedule_id' },
          { key: 'fiscal_year_id' },
          { key: 'event_type' },
          { key: 'event_name' },
          // { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('teaGardenBtriService.schedule') + ' ' + this.$t('globalTrans.entry') : this.$t('teaGardenBtriService.schedule') + ' ' + this.$t('globalTrans.modify')
    },
    viewTitle () {
      return (this.viewitemId === 0) ? this.$t('teaGardenBtriService.schedule') + ' ' + this.$t('globalTrans.view') : this.$t('teaGardenBtriService.schedule') + ' ' + this.$t('globalTrans.modify')
    },
    eventTypeList: function () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Training' : 'প্রশিক্ষণ' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Workshop' : 'কর্মশালা' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Course' : 'কোর্স' }
      ]
      return list
    },
    statusList: function () {
      // const list = [
      //   { value: 1, text: this.$i18n.locale === 'en' ? 'Pending' : 'অমীমাংসিত' },
      //   { value: 2, text: this.$i18n.locale === 'en' ? 'Circulated' : 'প্রচারিত' },
      //   { value: 3, text: this.$i18n.locale === 'en' ? 'Nominee Submitted' : 'নমিনি জমা দেওয়া হয়েছে' },
      //   { value: 4, text: this.$i18n.locale === 'en' ? 'Nominee Approved' : 'নমিনি অনুমোদিত' },
      //   { value: 5, text: this.$i18n.locale === 'en' ? 'Certified' : 'প্রত্যয়িত' }
      // ]
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Pending' : 'অমীমাংসিত' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Circulated' : 'প্রচারিত' },
        { value: 3, text: this.$i18n.locale === 'en' ? '' : '' },
        { value: 4, text: this.$i18n.locale === 'en' ? '' : '' },
        { value: 5, text: this.$i18n.locale === 'en' ? '' : '' }
      ]
      return list
    }
  },
  methods: {
    getEventName (data) {
      if (data.event_type === 1) {
        return this.$i18n.locale === 'en' ? data.training_name_en : data.training_name_bn
      } else if (data.event_type === 2) {
        return this.$i18n.locale === 'en' ? data.workshop_title_en : data.workshop_title_bn
      } else if (data.event_type === 3) {
        return this.$i18n.locale === 'en' ? data.course_name_en : data.course_name_bn
      }
    },
    circulateSchedule (id) {
      window.vm.$swal({
        title: window.vm.$t('teaGardenBtriService.schedule_publish_title'),
        showCancelButton: true,
        confirmButtonText: window.vm.$t('globalTrans.yes'),
        cancelButtonText: window.vm.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          const params = {
            status: 2
          }
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.getData(teaGardenServiceBaseUrl, `${prepareScheduleToggle}/${id}`, params).then(response => {
            if (response.success) {
              window.vm.$toast.success({
                title: window.vm.$t('globalTrans.success'),
                message: window.vm.$t('globalTrans.update_msg'),
                color: '#D6E09B'
              })
            } else {
              window.vm.$toast.error({
                title: 'Error',
                message: 'Operation failed! Please, try again.'
              })
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
          })
        }
      })
    },
    editMethodInParent (item, type) {
      if (type === 1) {
        this.edit(item)
      } else if (type === 2) {
        this.changeStatus(3, teaGardenServiceBaseUrl, prepareScheduleToggle, item)
      }
    },
    scheduleApprove (item) {
      this.item = item
    },
    forwarded (item) {
      this.forwardedItemId = item.id
      // service id custom  17
      this.service_id = 17
    },
    details (item) {
        this.items = item
    },
    searchData () {
      this.loadData()
    },
    getSource (id) {
        this.sourceOfFund.find(item => item.value === id)
    },
    getFiscalYear (yearId) {
        const cateObj = this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1).find(item => item.value === yearId)
        if (cateObj !== undefined) {
            if (this.$i18n.locale === 'bn') {
                return cateObj.text_bn
            } else {
                return cateObj.text_en
            }
        }
    },
    getEventType (sta) {
      const reqStatusObj = this.eventTypeList.find(tlaw => tlaw.value === parseInt(sta))
      return reqStatusObj.text
    },
    getStatusType (sta) {
      const reqStatusObj = this.statusList.find(tlaw => tlaw.value === parseInt(sta))
      return reqStatusObj.text
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(teaGardenServiceBaseUrl, prepareScheduleList, params)
      if (result.success) {
        const listData = result.data.data.map((item, index) => {
          return Object.assign({}, item, { serial: index })
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
